<template>
  <Maintenance v-if="isMaintenance" />
  <NuxtLayout v-else>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { useUtm } from "~/uses/useUtm";
import {
  setNewPasswordModal,
  useModalStore,
} from "~/modules/shared/components/modals/store/modalStore";
import { useMaintenanceStore } from "~/store/maintenanceStore";
import Maintenance from "~/modules/shared/components/maintenance/Maintenance.vue";
import { useAPI } from "~/uses/useMyFetch";
import { useUserStore } from "~/store/userStore";

const config = useRuntimeConfig();
const route = useRoute();

const languageStore = useLanguageFilterStore();
const userStore = useUserStore();
const modalStore = useModalStore();
const maintenanceStore = useMaintenanceStore();

const { getIsEnabled: isMaintenance } = storeToRefs(maintenanceStore);
const { getUserLanguage } = storeToRefs(languageStore);

const isRouteMatched = (name) => {
  return name === route.name.replace(/^[a-z]+_/, "");
};

onMounted(() => {
  if (import.meta.client) {
    userStore.fetch().finally(() => {
      const utm = useUtm(route.query);

      if (utm.isExists) {
        useAPI("/account/utm", {
          method: "POST",
          body: { ...utm.parameters },
        });
      }

      if (route.query.recovery) {
        modalStore.toggleModal(setNewPasswordModal);
      }
    });
  }
});

//Language settings
languageStore.setLanguage(route.name.match(/[^_]*/)[0]);

if (!isMaintenance.value) {
  if (import.meta.client) {
    !(function (t, e, c, n) {
      const s = e.createElement(c);
      (s.async = 1),
        (s.src = "https://statics.esputnik.com/scripts/" + n + ".js");
      const r = e.scripts[0];
      r.parentNode.insertBefore(s, r);
      const f = function () {
        // eslint-disable-next-line prefer-rest-params
        f.c(arguments);
      };
      f.q = [];
      f.c = function () {
        // eslint-disable-next-line prefer-rest-params
        f.q.push(arguments);
      };
      t["eS"] = t["eS"] || f;
    })(window, document, "script", "1CB3725191BF470B801BAEADA65491D0");

    eS("init");
  }

  useHead({
    htmlAttrs: {
      lang: () => getUserLanguage.value.name,
    },
    link: [
      {
        rel: "alternate",
        hreflang: "uk",
        href: () => {
          if (isRouteMatched("home")) {
            return config.public.appUrl;
          }

          if (getUserLanguage.value.name === "ru") {
            return config.public.appUrl + "/ua" + route.path;
          }

          return config.public.appUrl + route.path;
        },
      },
      {
        rel: "alternate",
        hreflang: "ru",
        href: () => {
          if (isRouteMatched("home")) {
            return config.public.appUrl + "/ru";
          }

          if (getUserLanguage.value.name === "uk") {
            const replacedPath = route.path.replace("/ua", "");

            return config.public.appUrl + replacedPath;
          }

          return config.public.appUrl + route.path;
        },
      },
      {
        rel: "canonical",
        href: () => {
          return config.public.appUrl + route.path;
        },
      },
    ],
  });
}
</script>
